import UrlPattern from 'url-pattern';

// Base Path
export const basePath = '/catalog';
export const BaseRoutePattern = new UrlPattern(basePath);

// Catalog Main Page
export const catalogBrowseTypePath = basePath + '/:type';

export interface CatalogBrowseTypeRouteParams {
	type: string;
}

export const CatalogBrowseRoutePattern = new UrlPattern(catalogBrowseTypePath);
export const buildCatalogBrowseRoute = (params: CatalogBrowseTypeRouteParams) => {
	return CatalogBrowseRoutePattern.stringify(params);
};

// Catalog Partner Page
export const partnerProfilePath = basePath + '/publisher';

// Catalog Details Page
export const catalogDetailsPath = basePath + '/details';

// Routes
export const catalogPaths = [
	basePath,
	catalogBrowseTypePath,
	partnerProfilePath,
	catalogDetailsPath,
];

export const findMatchingPath = (pathsList: string[], pathname: string) => {
	return pathsList.find(path => {
		return !!new UrlPattern(path).match(pathname);
	});
};
