import React from 'react';
import { Skeleton } from 'antd';
import { NavigationButtonProps } from './NavigationButton';
import { PartnerProfileContentExtensionProps } from './PartnerProfileContentExtension';
import { RequestInstallModalExtensionProps } from './RequestInstallModalExtension';

const LazyPartnerProfileContent = React.lazy(
	() => import('./PartnerProfileContentExtension')
);
export const AsyncLazyPartnerProfileContent: React.FC<
	PartnerProfileContentExtensionProps
> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyPartnerProfileContent {...props} />
	</React.Suspense>
);

const LazyRequestInstallModal = React.lazy(
	() => import('./RequestInstallModalExtension')
);
export const AsyncLazyRequestInstallModal: React.FC<
	RequestInstallModalExtensionProps
> = props => {
	return (
		<React.Suspense fallback={<Skeleton />}>
			<LazyRequestInstallModal {...props} />
		</React.Suspense>
	);
};

const LazyNavigationButton = React.lazy(() => import('./NavigationButton'));
export const AsyncLazyNavigationButton: React.FC<NavigationButtonProps> = props => {
	return (
		<React.Suspense fallback={<Skeleton />}>
			<LazyNavigationButton {...props} />
		</React.Suspense>
	);
};
