import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logError } from '../../../../logger';
import {
	DeleteSolutionParams,
	GetInstallationEntitlementParams,
	GetInstallationEntitlementResponse,
	GetInstallationLogsParams,
	GetInstallationLogsResponse,
	GetInstallationParams,
	GetInstallationResponse,
	GetSolutionParams,
	InstallSolutionParams,
	InstallSolutionResponse,
	ListSolutionsParams,
	RequestInstallSolutionParams,
	SolutionInfo,
	SolutionsSdkConfiguration,
	UninstallSolutionParams,
	UpdateSolutionParams,
	UpdateSolutionResponse,
} from './types';

export interface SolutionsSdk {
	listSolutions(params?: ListSolutionsParams): Promise<SolutionInfo[]>;
	getSolution(params: GetSolutionParams): Promise<SolutionInfo>;
	deleteSolution(params: DeleteSolutionParams): Promise<void>;
	installSolution(params: InstallSolutionParams): Promise<InstallSolutionResponse>;
	requestInstallSolution(params: RequestInstallSolutionParams): Promise<void>;
	updateSolution(params: UpdateSolutionParams): Promise<UpdateSolutionResponse>;
	uninstallSolution(params: UninstallSolutionParams): Promise<void>;
	getInstallation(params: GetInstallationParams): Promise<GetInstallationResponse>;
	getInstallationLogs(
		params: GetInstallationLogsParams
	): Promise<GetInstallationLogsResponse>;
	getInstallationEntitlement(
		params: GetInstallationEntitlementParams
	): Promise<GetInstallationEntitlementResponse>;
}

export function initializeSdk(configuration: SolutionsSdkConfiguration): SolutionsSdk {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: configuration.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const solutionsApi = axios.create(sharedConfig);

	return {
		listSolutions(params?: ListSolutionsParams): Promise<SolutionInfo[]> {
			const label = 'listSolutions';
			const url = '/v2/solutions';

			return apiProxy(async () => {
				return await solutionsApi.get(url, {
					params: {
						...params,
						...(!!params?.namespace && {
							namespace: encodeURIComponent(params.namespace),
						}),
					},
				});
			}, label);
		},
		getSolution(params: GetSolutionParams): Promise<SolutionInfo> {
			const label = 'getSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.id)}`;

			return apiProxy(async () => {
				return await solutionsApi.get(url);
			}, label);
		},
		deleteSolution(params: DeleteSolutionParams): Promise<void> {
			const label = 'deleteSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.id)}`;

			return apiProxy(async () => {
				return await solutionsApi.delete(url);
			}, label);
		},
		requestInstallSolution(params: RequestInstallSolutionParams): Promise<void> {
			const label = 'requestInstallSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.solutionRID)}/request`;

			return apiProxy(async () => {
				return await solutionsApi.post(url, params.args);
			}, label);
		},
		installSolution(params: InstallSolutionParams): Promise<InstallSolutionResponse> {
			const label = 'installSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.solutionId)}/install`;

			return apiProxy(async () => {
				return await solutionsApi.post(
					url,
					{
						containerRID: params.parentRID,
						args: params.args,
					},
					{
						params: { createEntitlement: params?.createEntitlement ?? false },
					}
				);
			}, label);
		},
		updateSolution(params: InstallSolutionParams): Promise<InstallSolutionResponse> {
			const label = 'updateSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.solutionId)}/upgrade`;

			return apiProxy(async () => {
				return await solutionsApi.patch(url, {
					containerRID: params.parentRID,
					args: params.args,
				});
			}, label);
		},
		uninstallSolution(params: UninstallSolutionParams): Promise<void> {
			const label = 'uninstallSolution';
			const url = `/v2/solutions/${encodeURIComponent(params.solutionId)}/uninstall`;

			return apiProxy(async () => {
				return await solutionsApi.post(url, {
					containerRID: params.containerRID,
				});
			}, label);
		},
		getInstallation(params: GetInstallationParams): Promise<GetInstallationResponse> {
			const label = 'getInstallation';
			const url = `/v2/solutions/installations/${params.id}`;

			return apiProxy(async () => {
				return await solutionsApi.get(url);
			}, label);
		},
		getInstallationLogs(
			params: GetInstallationLogsParams
		): Promise<GetInstallationLogsResponse> {
			const label = 'getInstallationLogs';
			const url = `/v2/executions/${params.executionId}/logs`;

			return apiProxy(async () => {
				return await solutionsApi.get(url);
			}, label);
		},
		getInstallationEntitlement(
			params: GetInstallationEntitlementParams
		): Promise<GetInstallationEntitlementResponse> {
			return apiProxy(async () => {
				return await solutionsApi.get('/v2/solutions/entitlements/install', {
					params: {
						catalogType: params.catalogType,
					},
				});
			}, 'getInstallationEntitlement');
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Solutions API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
