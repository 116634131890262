import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import {
	DeleteSolutionParams,
	GetInstallationEntitlementParams,
	GetInstallationEntitlementResponse,
	GetInstallationLogsParams,
	GetInstallationLogsResponse,
	GetInstallationParams,
	GetInstallationResponse,
	GetSolutionParams,
	initializeSdk,
	InstallSolutionParams,
	InstallSolutionResponse,
	ListSolutionsParams,
	RequestInstallSolutionParams,
	SolutionInfo,
	SolutionsSdk,
	UninstallSolutionParams,
	UpdateSolutionParams,
	UpdateSolutionResponse,
} from './sdk';

/**
 * Wraps the Solutions Axios SDK (see ./sdk/sdk.ts)
 */
export class SolutionsClient {
	private initializedSDK: SolutionsSdk;

	public Initialize(sdk: SolutionsSdk) {
		this.initializedSDK = sdk;
	}

	public listSolutions(params?: ListSolutionsParams): Promise<SolutionInfo[]> {
		return this.initializedSDK.listSolutions(params);
	}

	public getSolution(params: GetSolutionParams): Promise<SolutionInfo> {
		return this.initializedSDK.getSolution(params);
	}

	public deleteSolution(params: DeleteSolutionParams): Promise<void> {
		return this.initializedSDK.deleteSolution(params);
	}

	public installSolution(
		params: InstallSolutionParams
	): Promise<InstallSolutionResponse> {
		return this.initializedSDK.installSolution(params);
	}

	public requestInstallSolution(params: RequestInstallSolutionParams): Promise<void> {
		return this.initializedSDK.requestInstallSolution(params);
	}

	public updateSolution(params: UpdateSolutionParams): Promise<UpdateSolutionResponse> {
		return this.initializedSDK.updateSolution(params);
	}

	public uninstallSolution(params: UninstallSolutionParams): Promise<void> {
		return this.initializedSDK.uninstallSolution(params);
	}

	public getInstallation(
		params: GetInstallationParams
	): Promise<GetInstallationResponse> {
		return this.initializedSDK.getInstallation(params);
	}

	public getInstallationLogs(
		params: GetInstallationLogsParams
	): Promise<GetInstallationLogsResponse> {
		return this.initializedSDK.getInstallationLogs(params);
	}

	public getInstallationEntitlement(
		params: GetInstallationEntitlementParams
	): Promise<GetInstallationEntitlementResponse> {
		return this.initializedSDK.getInstallationEntitlement(params);
	}
}

export const solutionsClient = new SolutionsClient();

export function configureSolutionsClient(app: PiletApi) {
	solutionsClient.Initialize(
		initializeSdk({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
